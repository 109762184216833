import {
  classes as categoriesBreadcrumbsStylable,
  st as categoriesBreadcrumbsStyles,
} from './CategoriesBreadcrumbs.st.css';
import styles from './CategoriesBreadcrumbs.scss';
import React from 'react';
import {Breadcrumbs} from 'wix-ui-tpa/cssVars';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {DataHook} from '../../../common/ExtendedGridGallery/ExtendedGridGallery';
import classNames from 'classnames';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Experiments} from '../../../constants';

export const CategoriesBreadcrumbs: React.FunctionComponent<IGalleryGlobalProps> = withGlobals(
  (props: IGalleryGlobalProps) => {
    const {
      taxonomicalBreadcrumbs,
      breadcrumbsHistory,
      shouldShowMobile,
      currentCategory,
      handleCategoryBreadcrumbsClick,
    } = props.globals;
    const {experiments} = useExperiments();

    const breadcrumbItems = experiments.enabled(Experiments.ShouldRenderTaxonomicalBreadcrumbsInSF)
      ? taxonomicalBreadcrumbs
      : breadcrumbsHistory;

    return (
      <div data-hook={DataHook.Breadcrumbs} className={styles.breadcrumbsWrapper}>
        <Breadcrumbs
          items={breadcrumbItems}
          onClick={(item) => handleCategoryBreadcrumbsClick({item, originCategoryId: currentCategory.id})}
          className={classNames(
            categoriesBreadcrumbsStyles(categoriesBreadcrumbsStylable.breadcrumbs, {
              mobile: shouldShowMobile,
            })
          )}
          data-hook={DataHook.BreadcrumbsComponent}
          showTrailOnMobileMode={true}
        />
      </div>
    );
  }
);
